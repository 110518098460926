/* eslint-disable */

//import { PublicClientApplication, EventType, InteractionStatus } from "@azure/msal-browser";
import { loginRequest, msalConfig} from "../auth/authConfig.js";

let msal = await import("@azure/msal-browser");
const msalInstance = new msal.PublicClientApplication(msalConfig);

msalInstance.initialize().then(async () => {

    window.close();
    loginRequest.account = msalInstance.getAllAccounts()[0];
    loginRequest.postLogoutRedirectUri = msalConfig.auth.postLogoutRedirectUri;
    loginRequest.mainWindowRedirectUri = msalConfig.auth.redirectUri;
    await msalInstance.logoutRedirect(loginRequest);
    
});